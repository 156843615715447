/*
Set the document meta-json field with the defined headings/types for the columns of the form
that is expected
*/

import React, { useState, useEffect } from "react";
import Traec from "traec";

import { BSModal } from "traec-react/utils/bootstrap";
import { ErrorBoundary } from "traec-react/errors";

import { AddEditFormFields } from "./fields";
import { setAndShowModal } from "AppSrc/utils/modal";

function InputTypeSelector({ value, onChangeHandler }) {
  return (
    <div className="form-group">
      <label htmlFor="inputTypeSelect">File/Input Type</label>
      <select value={value} className="form-control" id="inputTypeSelect" onChange={onChangeHandler}>
        <option value="upload">Upload</option>
        <option value="form">User-defined Form</option>
      </select>
    </div>
  );
}

export function FormDetails({ details = Traec.Im.Map(), setDetails }) {
  return (
    <React.Fragment>
      <div className="form-group">
        <label htmlFor="formName">Form Name</label>
        <input
          value={details.get("name") || ""}
          type="text"
          className="form-control"
          id="formName"
          onChange={e => {
            setDetails(details.set("name", e.target.value));
          }}
        />
      </div>
    </React.Fragment>
  );
}

function FormFields({ inputType, details, fields, setDetails, setFields }) {
  if (!(inputType == "form")) {
    return null;
  }

  return (
    <ErrorBoundary>
      <hr />
      <FormDetails details={details} setDetails={setDetails} />
      <div className="row">
        <div className="col">
          <label>Form Fields</label>
        </div>
      </div>
      <AddEditFormFields fields={fields} setFields={setFields} />
      <hr />
    </ErrorBoundary>
  );
}

const saveMeta = (e, props) => {
  e.preventDefault();
  let { trackerId, commitId, refId, path, modalId, setPending, inputType, details, fields } = props;

  let fetch = new Traec.Fetch("tracker_node", "put", { trackerId, refId, commitId, pathId: path });
  fetch.updateFetchParams({
    preFetchHook: body => {
      let meta_json = {
        input_type: inputType,
        input_details: {
          ...details.toJS(),
          fields: fields.toJS()
        }
      };
      //console.log("Setting document meta_json", meta_json);
      return {
        type: "document",
        node: {
          document: {
            meta_json
          }
        }
      };
    },
    postSuccessHook: e => {
      setPending(false);
      $(`#${modalId}`).modal("hide");
    },
    postFailureHook: data => {
      setPending(false);
    }
  });

  setPending(true);
  fetch.dispatch();
};

export function DocumentMetaMenu(props) {
  let { document } = props;

  if (!document) {
    return null;
  }

  const [docId, setDocId] = useState(document.get("uid") || "");
  const [details, setDetails] = useState(document.getInPath("meta_json.input_details") || Traec.Im.Map());
  const [fields, setFields] = useState(document.getInPath("meta_json.input_details.fields") || Traec.Im.List());
  const [inputType, setInputType] = useState(document.getInPath("meta_json.input_type") || "");
  const [pending, setPending] = useState(false);

  useEffect(() => {
    // Reset all of the fields if we have a different document.uid
    if (document.get("uid") != docId) {
      //console.log("Got a different document.uid: resetting modal state parameters", docId, document.get("uid"));
      setDocId(document.get("uid"));
      setDetails(document.getInPath("meta_json.input_details"));
      setFields(document.getInPath("meta_json.input_details.fields"));
      setInputType(document.getInPath("meta_json.input_type"));
      setPending(false);
    }
  });

  return (
    <ErrorBoundary>
      <InputTypeSelector
        value={inputType}
        onChangeHandler={e => {
          setInputType(e.target.value);
        }}
      />
      <FormFields
        inputType={inputType}
        details={details}
        fields={fields}
        setDetails={setDetails}
        setFields={setFields}
      />

      <div className="row">
        <div className="col">
          <button
            className="btn btn-sm btn-primary"
            onClick={e =>
              saveMeta(e, {
                ...props,
                setPending,
                inputType,
                details,
                fields
              })
            }
          >
            {pending ? <div className="spinner-border spinner-border-sm text-light" role="status" /> : "Save"}
          </button>
        </div>
      </div>
    </ErrorBoundary>
  );
}

export const documentMetaModal = props => {
  let modalId = "CommonDocMetaModal001";
  setAndShowModal(modalId, {
    title: "Setup File Form Fields",
    immutableBodyProps: true,
    body: <DocumentMetaMenu {...props} modalId={modalId} />
  });
};
