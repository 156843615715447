import React from "react";
import EmissionsLineChart from "../emissionsChart.js";
import ScopesDoughnut from "storybook-dashboard/dashboard/carbonComponents/scopesDoughnut.js";
import TotalEmissionsCount from "storybook-dashboard/dashboard/carbonComponents/totalEmissionsCount.js";
import EmissionsPerTurnover from "storybook-dashboard/dashboard/carbonComponents/emissionsPerTurnoverCount.js";
import { ErrorBoundary } from "storybook-dashboard/errors";

const ChartCard = ({ title, children }) => {
  return (
    <div className="col-sm-12 col-md-12 col-lg-6">
      <div className="shadow p-3 my-3 bg-white rounded">
        <h5 className="card-title">{title}</h5>
        <p className="text-muted">Hover over the dots to see the emissions for year and % change</p>
        {children}
      </div>
    </div>
  );
};

export default function SummaryTab({ projectId, refId, companyId }) {
  return (
    <ErrorBoundary>
      <div className="d-flex flex-column flex-md-row justify-content-between mb-3">
        <div className="col-sm-12 col-md-6">
          <ErrorBoundary>
            <ScopesDoughnut projectId={projectId} refId={refId} />
          </ErrorBoundary>
        </div>

        <div className="col-sm-12 col-md-6">
          <ErrorBoundary>
            <TotalEmissionsCount projectId={projectId} refId={refId} />
          </ErrorBoundary>

          {/* <ErrorBoundary>
            <EmissionsPerTurnover projectId={projectId} refId={refId} companyId={companyId.substring(0, 8)} />
          </ErrorBoundary> */}
        </div>
      </div>

      <div className="d-flex flex-column flex-md-row justify-content-between">
        <ChartCard title="Total emissions over time">
          <EmissionsLineChart projectId={projectId} refId={refId} />
        </ChartCard>

        <ChartCard title="Total emissions per turnover">
          <EmissionsLineChart projectId={projectId} refId={refId} total={false} />
        </ChartCard>
      </div>
    </ErrorBoundary>
  );
}
