import React from "react";
import { ErrorBoundary } from "storybook-dashboard/errors";
import IssuesRAGPanel from "storybook-dashboard/dashboard/ragPanels/issues";
import IndicatorsRAGPanel from "storybook-dashboard/dashboard/ragPanels/indicators";
import IndicatorsBarChart from "storybook-dashboard/dashboard/charts/verticalBar";
import DataExplorer from "storybook-dashboard/dashboard/components/dataExplorer";
import InputValuesTable from "storybook-dashboard/dashboard/project/inputValues";

const explorerOptionsProject = {
  performance: {
    text: "Input values table",
    component: InputValuesTable,
  },
};

const explorerOptionsReportingPackage = {
  performance: {
    text: "Input values table",
    component: InputValuesTable,
  },
};

export default function AnalyseHotspotsTab({
  projectId,
  refId,
  iconPath,
  selectedIssue,
  setSelectedIssue,
  filters,
  selectedIndicators,
  selectedIndicatorsById,
  setSelectedIndicatorsById,
}) {
  return (
    <>
      <ErrorBoundary>
        <IssuesRAGPanel
          projectId={projectId}
          refId={refId}
          iconPath={iconPath}
          selected={selectedIssue}
          setSelected={setSelectedIssue}
          filters={filters}
        />
      </ErrorBoundary>

      <ErrorBoundary>
        <IndicatorsRAGPanel
          projectId={projectId}
          refId={refId}
          iconPath={iconPath}
          issue={selectedIssue}
          selected={selectedIndicatorsById}
          setSelected={setSelectedIndicatorsById}
          filters={filters}
        />
      </ErrorBoundary>

      <ErrorBoundary>
        <IndicatorsBarChart
          projectId={projectId}
          refId={refId}
          indicators={selectedIndicators}
          filters={filters}
          period="year"
        />
      </ErrorBoundary>

      <ErrorBoundary>
        <DataExplorer
          projectId={projectId}
          refId={refId}
          indicators={selectedIndicators}
          options={refId == "root" ? explorerOptionsProject : explorerOptionsReportingPackage}
        />
      </ErrorBoundary>
    </>
  );
}
