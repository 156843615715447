import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import Traec from "traec";
import { ProjectPermission } from "traec/utils/permissions/project";
import { getProjectProps } from "AppSrc/project/utils";

import WorkPackageRow from "./row";

export const counter = { row: 0 };

class WPTree extends React.Component {
  constructor(props) {
    super(props);

    this.requiredFetches = [new Traec.Fetch("tracker_commit_branch", "list")];
  }

  componentDidMount() {
    Traec.fetchRequiredFor(this);
  }

  render() {
    let { projectId, cref, rootRef, company } = this.props;

    let companyName = company ? company.get("name") : null;
    let companyId = company ? company.get("uid") : null;

    if (!cref || !companyId || !rootRef) {
      return null;
    }

    companyId = companyId ? companyId.substring(0, 8) : companyId;
    counter.row = 0;

    return (
      <ProjectPermission projectId={projectId} requiredActions={["READ_TRACKER_REF"]}>
        <div className="container-fluid m-0 p-0">
          <h5>Reporting Packages</h5>

          <Link to={`/company/${companyId}`}>
            <p className={`m-0 p-0`}>
              <b>{companyName}</b>
            </p>
          </Link>

          <WorkPackageRow {...this.props} />
        </div>
      </ProjectPermission>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { cref, projectId } = ownProps;
  let { company, trackerId } = getProjectProps(state, projectId);

  // Get the root tree from the cref
  let commit = cref ? cref.get("latest_commit") : null;
  let rootTreeId = commit ? commit.get("root_tree") : null;
  let rootTree = rootTreeId ? state.getInPath(`entities.trees.byId.${rootTreeId}`) : null;

  //
  return { trackerId, commit, rootTree, company };
};

export default connect(mapStateToProps)(WPTree);
