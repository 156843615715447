import React from "react";
import SupplierBreakdownTable from "../SupplierBreakdownTable.js";
import { ErrorBoundary } from "storybook-dashboard/errors";

export default function SupplierBreakdownTab({ projectId }) {
  return (
    <ErrorBoundary>
      <SupplierBreakdownTable projectId={projectId} />
    </ErrorBoundary>
  );
}
