import React from "react";
import { ErrorBoundary } from "storybook-dashboard/errors";
import CarbonSupplierReport from "../../../company/supplierReport";

export default function SuppliersReportedTab({ projectId, refId, companyId, number }) {
  return (
    <ErrorBoundary>
      <CarbonSupplierReport projectId={projectId} refId={refId} companyId={companyId.substring(0, 8)} number={number} />
    </ErrorBoundary>
  );
}
