import React from "react";
import { connect } from "react-redux";
import Traec from "traec";
import Octicon from "react-octicon";

export const current_path = [""];

function Grabber({ show, path, selected, dispatch }) {
  if (!show) {
    return null;
  }
  selected = selected || Traec.Im.Set();

  const onClick = e => {
    let _selected = Traec.Im.Set();
    if (e.ctrlKey) {
      //console.log("Handling multi-select for path", path, selected.toJS());
      _selected = selected.has(path) ? selected.delete(path) : selected.add(path);
    }
    dispatch({
      type: "UI_SET_IN",
      payload: _selected,
      stateParams: { itemPath: `dnd` }
    });
  };

  const onDragStart = e => {
    //console.log("onDragStart DragDropHandle");
    e.dataTransfer.setData("from_path", e.target.id);
    current_path[0] = e.target.id;
  };

  return (
    <div
      id={path}
      style={{ backgroundColor: selected.has(path) ? "#bfbfbf" : null }}
      onClick={onClick}
      onDragStart={onDragStart}
      draggable={true}
    >
      <Octicon name="grabber" className="ml-2" />
    </div>
  );
}

const mapStateToProps = state => {
  let selected = state.getInPath("ui.dnd") || Traec.Im.Set();
  return { selected };
};

export default connect(mapStateToProps)(Grabber);
