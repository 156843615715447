import React from "react";
import { connect } from "react-redux";
import Traec from "traec";

import { confirmDelete, confirmProceed } from "traec-react/utils/sweetalert";
import IndicatorRow from "traec-react/equations";

import { CreateIndicatorForm } from "./form";
import { MetricTargetSet } from "AppSrc/dashboards/targetSet";
import { ErrorBoundary } from "traec-react/errors/handleError";

class ProjectIndicatorRow extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      fetchedEdges: false,
      fetchedIndicators: false,
      showSetTargetForm: false,
      showEditForm: false
    };

    this.toggleForm = this.toggleForm.bind(this);
    this.deleteIndicator = this.deleteIndicator.bind(this);
    this.editIndicator = this.editIndicator.bind(this);
    this.addRetro = this.addRetro.bind(this);
    this.toggleSetTargetForm = this.toggleSetTargetForm.bind(this);
    this.toggleGreenBelow = this.toggleGreenBelow.bind(this);
    this.deleteTarget = this.deleteTarget.bind(this);
  }

  /**********************
     COMPONENT METHODS
     **********************/

  /**********************
     MENU OPERATIONS
     **********************/

  dropDownLinks() {
    let { indicator } = this.props;
    let metricTarget = indicator.get("metricTarget");
    let raThreshold = metricTarget ? metricTarget.getInPath("meta_json.thresholdLow") : null;
    let greenBelow = metricTarget ? metricTarget.getInPath("meta_json.greenBelow") : null;

    let items = [
      //{ name: "Edit Indicator", onClick: this.editIndicator },
      //{},
      { name: "Set Targets", onClick: this.toggleSetTargetForm },
      {
        name: "Set Cumulative Target",
        linkTo: this.cumulativeTargetLink()
      },
      {},
      { name: "Add to all Reports", onClick: this.addRetro },
      {},
      { name: "Delete", onClick: this.deleteIndicator }
    ];
    if (metricTarget && !raThreshold) {
      let name = greenBelow ? "Set Green Above Target" : "Set Green Below Target";
      items.push({ name: name, onClick: this.toggleGreenBelow });
    }
    if (indicator.get("metricTarget")) {
      items.push({ name: "Delete Target", onClick: this.deleteTarget });
    }
    return items;
  }

  cumulativeTargetLink() {
    let { isRootRef, indicator, projectId, refId } = this.props;
    if (isRootRef) {
      return `/project/${projectId}/indicator/${indicator.get("uid")}/target`;
    } else {
      return `/project/${projectId}/wpack/${refId}/indicator/${indicator.get("uid")}/target`;
    }
  }

  addRetro(e) {
    let { indicator, trackerId, commitId } = this.props;
    let indicatorId = indicator.get("uid");
    let indicatorName = indicator.getInPath("metric.name") || "";

    confirmProceed({
      text: `This will add this indicator ${indicatorName} to all reports in this Reporting Package, including past reports that did not include the indicator at the time.  Are you sure you would like to proceed?`,
      onConfirm: () => {
        let fetch = new Traec.Fetch("tracker_commit_indicator", "patch", { trackerId, commitId, indicatorId });
        fetch.updateFetchParams({
          body: { apply_ref_all: true }
        });
        fetch.dispatch();
      }
    });
  }

  editIndicator(e) {
    e.preventDefault();
    //console.log("Turning on showEditForm");
    this.setState({ showEditForm: true });
  }

  deleteIndicator(e) {
    e.preventDefault();
    let { indicator, trackerId, commitId } = this.props;
    let indicatorId = indicator.get("uid");
    let indicatorName = indicator.getInPath("metric.name") || "";

    confirmDelete({
      text: `This will delete this indicator ${indicatorName}.  Are you sure you would like to proceed?`,
      onConfirm: () => {
        new Traec.Fetch("tracker_commit_indicator", "delete", { trackerId, commitId, indicatorId }).dispatch();
      }
    });
  }

  deleteTarget(e) {
    e.preventDefault();
    let { trackerId, commitId, indicator } = this.props;
    let indicatorName = indicator.getInPath("metric.name") || "";
    let metricTargetId = indicator.getInPath("metricTarget.uid") || null;
    if (!metricTargetId) {
      return null;
    }

    confirmDelete({
      text: `This will delete the target set for indicator ${indicatorName}.  Are you sure you would like to proceed?`,
      onConfirm: () => {
        new Traec.Fetch("tracker_commit_target", "delete", {
          trackerId,
          commitId,
          metricTargetId,
          all_ref: true
        }).dispatch();
      }
    });
  }

  toggleGreenBelow(e) {
    e.preventDefault();
    let { trackerId, commitId, indicator } = this.props;
    let metricTarget = indicator.get("metricTarget");
    if (!metricTarget) {
      return null;
    }
    let metricTargetId = metricTarget.get("uid") || null;
    let greenBelow = metricTarget ? metricTarget.getInPath("meta_json.greenBelow") : null;

    let fetch = new Traec.Fetch("tracker_commit_target", "patch", { trackerId, commitId, metricTargetId });
    fetch.updateFetchParams({
      preFetchHook: data => ({ meta_json: { greenBelow: !greenBelow } })
    });
    fetch.dispatch();
  }

  toggleForm(e) {
    e.preventDefault();
    this.setState({ showNewForm: !this.state.showNewForm });
  }

  toggleSetTargetForm(e) {
    e.preventDefault();
    this.setState({ showSetTargetForm: !this.state.showSetTargetForm });
  }

  /**********************
     RENDER METHODS
     **********************/

  render_edit_form() {
    if (!this.state.showEditForm) {
      return null;
    }
    let { indicator, projectId, commitId, trackerId, baseMetrics, dispatch } = this.props;

    return (
      <div className="row">
        <div className="col-sm-12 align-middle">
          <CreateIndicatorForm
            projectId={projectId}
            trackerId={trackerId}
            commitId={commitId}
            baseMetrics={baseMetrics}
            dispatch={dispatch}
            toggleShowHandler={e => {
              e.preventDefault();
              this.setState({ showEditForm: !this.state.showEditForm });
            }}
            indicator={indicator}
            dispatchType={"put"}
          />
        </div>
      </div>
    );
  }

  render_set_targets() {
    let { indicator, dispatch, commitId, trackerId } = this.props;
    if (!this.state.showSetTargetForm) {
      return null;
    }
    return (
      <MetricTargetSet
        header={<hr />}
        indicator={indicator}
        commitId={commitId}
        trackerId={trackerId}
        dispatch={dispatch}
        closeButtonHandler={this.toggleSetTargetForm}
      />
    );
  }

  render() {
    let { baseMetrics, indicator } = this.props;

    return (
      <React.Fragment>
        <IndicatorRow
          baseMetrics={baseMetrics}
          indicator={indicator}
          dropDownLinks={this.dropDownLinks()}
          setTargetComponent={this.render_set_targets()}
        />
        <ErrorBoundary>{this.render_edit_form()}</ErrorBoundary>
      </React.Fragment>
    );
  }
}

const mapDispatchToProps = dispatch => ({ dispatch });
export default connect(mapDispatchToProps)(ProjectIndicatorRow);
